<template>
    <div>
        <div class="lg_main">
            <div class="lg_header"><span data-v-92e12380="">&nbsp;</span></div>
            <div class="lg_from">
                <div class="kcmc">考场11</div>
                <div class="shenfenz">
                    <span>准考证号：</span>
                    <input type="text" value="111111111111">
                </div>
                <div class="zhenjian">
                    <span>身份证号：</span>
                    <input type="text" value="8888888888888">
                </div>
                <div class="buttonk">
                    <!--点击进入下个页面-->
                    <router-link :to="{name: 'subjectivePaperBefore', query: {id: this.$route.query.id, sourceId: this.$route.query.sourceId, sourceType: this.$route.query.sourceType}}">
                        <input type="button" onmouseout="this.className='buttonDlL'"
                               onmouseover="this.className='buttonDl buttonDlOnlie'" id="loginbtn" class="buttonDlL">
                    </router-link>
                    <input type="button" onmouseout="this.className='buttonCz'"
                           onmouseover="this.className='buttonDl buttonCzOnlie'" id="resetbtn" class="buttonCz">
                </div>
            </div>
            <div style="margin-top: -100px; margin-left: 450px; font-weight: bold; text-align: left;">
                <span>请按F11进入全屏</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "paperLogin",
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#2d6dae')
    },
    mounted() {
        if (!this.$route.query.id) {
            this.$router.push({name: 'index'})
        }
    }
}
</script>

<style scoped>

.buttonDlOnlie {
    background: url(../../../assets/img/mkbgicon.png) no-repeat -491px 0;
}

.buttonCzOnlie {
    background: url(../../../assets/img/mkbgicon.png) no-repeat -242px 0;
    margin-left: 5px;
}

.buttonDlL {
    width: 75px;
    height: 28px;
    border: 0;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -327px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.buttonDl {
    width: 75px;
    height: 28px;
    border: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.buttonCz {
    margin-left: 5px;
    width: 75px;
    height: 28px;
    border: 0;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -84px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.buttonk {
    width: 160px;
    height: 30px;
    margin-top: 30px;
    margin-left: 270px;
}

.zhenjian {
    margin-top: 14px;
    margin-left: 200px;
    text-align: left;
}

.shenfenz {
    margin-left: 200px;
    text-align: left;
}

.kcmc {
    margin-top: -40px;
    margin-bottom: 10px;
    margin-left: 140px;
    text-align: center;
    color: red;
    font-weight: 500;
    font-size: 26px;
}

.lg_from {
    width: 526px;
    height: 291px;
    margin: auto;
    padding-top: 120px;
    font-family: \\5B8B\4F53;
    font-size: 14px;
    -webkit-box-sizing: initial;
    box-sizing: initial;
    background: url(../../../assets/img/paperLogin-bg.png) no-repeat;
}

.lg_header {
    width: 100%;
    height: 150px;
    margin-top: 100px;
    text-align: center;
}

.lg_main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translateX(-50%) translateY(-50%);
    width: 1024px;
    height: 768px;
    background: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAQDAwQDAwQEBAQFBQQFBwsHBwYGBw4KCggLEA4RERAOEA8SFBoWEhMYEw8QFh8XGBsbHR0dERYgIh8cIhocHRz/2wBDAQUFBQcGBw0HBw0cEhASHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBz/wgARCAMAAAEDAREAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDAQj/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAgEF/9oADAMBAAIQAxAAAAD1R2OSAAAAAAAAAAKlRTe40V3F4vNtVlys7LVV5tFStVYsqd6WdCgOJkmmbM9QnO8wTnTOmSc9zKpgi8gAAAAAAAAAAAAAAAH/xAAcEAEBAQABBQAAAAAAAAAAAAAAERIQASBAUGH/2gAIAQEAAT8A8XPOUToyjKcRlGUROMp8RlO2qqq02qq02rSteg//xAAaEQEAAwADAAAAAAAAAAAAAAARECAwAEBQ/9oACAECAQE/AOq2MG7iScLkHgf/xAAWEQEBAQAAAAAAAAAAAAAAAAARUED/2gAIAQMBAT8AkmJi/wD/2Q==) repeat-x;
}

body {
    min-width: 1200px;
    width: 100%;
    height: 100%;
    background: #2d6dae;
    overflow: hidden;
}
</style>